@import "../../styles/variables";
@import"../../styles/mixins";

.footer {
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 0;
    background-color: $secondary-grey;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
        @include tablet {
            height: 220px;
        }
        @include desktop {
            height: 345px;
        }
        @include mac {
            height: 300px;
        }
    &__child-overlay {
        width: 100%;
        height: 55%;
        background-color: #343538;
        position: absolute;
        transform-origin: left bottom;
        top: 0px;
        left: 0px;
        transform: skew(0deg, -8deg);
        @include mac {
            height: 75%;
        }
    }
    &__text {
        text-transform: uppercase;
        color: $white;
        margin: 0 auto 24px;
        width: 90%;
        text-align: end;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include tablet {
            width: 95%;
            margin: 30px;
        }
    }
    &__react-icon {
        height: 16px;
    }
}