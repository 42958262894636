@import "../../styles/variables";
@import"../../styles/mixins";

.about {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 65px;
    cursor: default;
    @include desktop {
        width: 65%;
        margin-bottom: 130px;
    }
    &__content {
        text-transform: uppercase;
        line-height: 1.5;
        font-size: .8rem;
        font-weight: 500;
        line-height: 2;
        @include tablet {
            font-size: 1rem;
        }
        @include desktop {
            font-size: 1.2rem;
        }
    }
}