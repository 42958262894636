@import "../../styles/mixins";
@import"../../styles/mixins";

.hero {
    width: 90%;
    margin: 80px auto;
    // cursor: default;
    @include tablet {
        margin: 0 auto 250px;
      }
      @include desktop {
        margin: 0 auto 360px;
      }
    &__design-wrapper {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__line {
        background-color: $white;
        width: 1px;
        height: 85px;
        @include tablet {
            height: 190px;
        }
        @include desktop {
            height: 250px;
        }
    }
    &__header-wrapper {
        width: 100%;
        margin: 23.5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__header {
        font-weight: bold;
        font-size: 1.2rem;
        width: 50%;
        text-align: right;
        margin-right: 0px;
        @include tablet {
            font-size: 2.4rem;
            letter-spacing: 6px;
        }
        @include desktop {
            font-size: 3.5rem;
        }
    }
    &__sub-header {
        font-size: .83rem;
        font-weight: 200;
        text-align: left;
        margin-left: 14%;
        width: 50%;
        margin-right: 0;
        @include tablet {
            font-size: 1.24rem;
        }
        @include desktop {
            font-size: 2.1rem;
        }
    }
}