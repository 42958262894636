@import "../../styles/variables";
@import"../../styles/mixins";

.header {
    padding: 8px 6px 8px 18px;
    font-size: 1.2rem;
    // cursor: default;
    @include tablet {
        font-size: 2rem;
    }
    &__wrapper {
        width: 100%;
        border: 1px solid $white;
        margin-bottom: 24px;
        @include desktop {
        margin-bottom: 65px;
        }
    }
}