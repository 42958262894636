// COLORS
$grey: #343538;
$secondary-grey: #4D4D50;
$white: #ffffff;
$blue: #0366d6;
$aqua: #62d9fb;
$black: #000000;
$yellow: #ffd33d;

// BREAKPOINTS
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1200px;
$mac-breakpoint: 1440px;
