@import "../../styles/variables";
@import"../../styles/mixins";

.contact {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 12px;
    @include tablet {
        margin-bottom: 20px;
    }
    @include desktop {
        width: 70%;
        margin-bottom: 45px;
    }
    &__info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 40px;
        margin-bottom: 40px;
        font-size: .9rem;
        // cursor: default;
        @include tablet {
            height: 50px;
            margin-bottom: 40px;
            font-size: 1.2rem;
        }
    }
    &__social-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 75px;
        @include tablet {
            height: 100px;
            font-size: 1.2rem;
        }
    }
    &__social-link {
        text-decoration: none;
        color: $aqua;
        // font-weight: bold;
        width: 100px;
    }
    &__social-link:hover {
        font-weight: bold;
    }
    &__name {
        margin: 0;
        font-size: .9rem;
        @include tablet {
            font-size: 1.2rem;
        }
    }
}


