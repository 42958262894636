@import "variables";

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin mac {
  @media screen and (min-width: $mac-breakpoint) {
    @content;
  }
}

// FOR HERO ANIMATION AT LATER DATE
// @mixin animation-keyframes {
  @keyframes shine {
    from {
      -webkit-mask-position: 150%;
    }
    
    to {
      -webkit-mask-position: -50%;
    }
  }
  
// }