@import "../styles/variables";
@import"../styles/mixins";

main {
    width: 100%;
    height: 100%;
    background-color: $grey;
    color: $white;
    font-family: cresta, sans-serif;
    letter-spacing: 1px;
    @include desktop {
        // max-width: 1200px;
        // margin: auto;
    }
}