@import "../../styles/mixins";
@import"../../styles/mixins";

.navbar {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    @include tablet {
        width: 95%;
    }
    &__list {
        width: 50%;
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        font-size: .5rem;
        list-style: none;
        letter-spacing: 3px;
        @include tablet {
            font-size: .6rem;
            width: 31%;
        }
        @include desktop {
            font-size: 1rem;
            width: 25%;
        }
    }
    &__item-link {
        text-decoration: none;
        color: $aqua;
    }
    &__item {
        width: 100%;
        cursor: pointer;
    }
    &__item:hover {
        font-weight: bold;
    }
}