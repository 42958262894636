@import "../../styles/variables";
@import"../../styles/mixins";

.work {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    @include desktop {
        width: 65%;
    }
    &__list-wrapper {
        margin: 12px 0 0 0;
        @include desktop {
            margin: 12px 0 65px 0;
        }
    }
    &__item-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 90px;
        justify-content: space-between;
            align-items: flex-start;
        @include tablet {
            flex-direction: row;
            margin-bottom: 130px;
        }
    }

    @keyframes shine {
        from {
          -webkit-mask-position: 150%;
        }
        
        to {
          -webkit-mask-position: -50%;
        }
      }
    &__item-img-wrapper {
        height: auto;
        width: 100%;
        :hover {
            -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
            -webkit-mask-size: 200%;
            animation: shine 2s infinite;
            // border: solid $blue;
        }
        @include tablet {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        }
    }

    &__item-img-wrapper-null {
        height: auto;
        width: 100%;
        @include tablet {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        }
    }
    &__item-img {
        width: 100%;
        height: auto;
    }
    &__item-info {
        margin-top: 24px;
        // cursor: default;
        @include tablet {
            margin-top: 0;
            width: 55%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        @include desktop {
            width: 55%;
        }
    }
    &__item-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }
    &__item-title {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin: 0;
        @include tablet {
            font-size: 1.2rem;
        }
        @include desktop {
            font-size: 1.5rem;
        }
    }
    &__item-github-link {
        height: 22px;
        border: solid $white;
        background-color: $blue;
        border-radius: 3px;
        @include tablet {
            height: 27px;
            margin-right: 24px;
        }
        &:hover {
            border: solid $blue;
            background-color: $grey;
            transition: border 1s, background-color 1s ease-out;
        }
    }
    &__item-github-link-null {
        height: 22px;
        display: none;
        @include tablet {
            height: 27px;
            margin-right: 24px;
        }
        &:hover {
            border: solid $blue;
            background-color: $grey;
            transition: border 1s, background-color 1s ease-out;
        }
    }
    &__item-desc {
        font-size: .9rem;
        line-height: 1.5;
        @include tablet {
            font-size: .8rem;
        }
        @include desktop {
            font-size: 1.2rem;
        }
    }
    &__item-tools-wrapper {
        display: flex;
        justify-content: space-between;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: bold;
        @include desktop {
            margin-right: 32px;
        }
    }   
    &__item-tool {
        font-size: .8rem;
        color: $yellow;
        @include desktop {
            font-size: 1rem;
        }
    }
}